<script>
import { postVoucher, putVoucher } from "@/newApi/market";
import { getGoodsSelect } from "@/newApi/commit";
export default {
  name: "deawerMarket",
  props: {
    type: {
      type: Number,
      default: 1,
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    operate: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      operate_type: false,
      expired_day1: "",
      expired_day2: "",
      options: [],
      value1: [],
      typeList: [
        {
          value: 1,
          label: "满减券",
        },
        {
          value: 2,
          label: "折扣券",
        },
      ],
    };
  },
  //监视
  watch: {
    value1: function(val) {
      console.log(val);
      if (val.length > 0) {
        this.info.use_date_type = 1;
        this.expired_day1 = "";
        this.expired_day2 = "";
        this.info.after_day = "";
      }
    },
    expired_day1: function(val) {
      if (val) {
        this.info.use_date_type = 2;
        this.expired_day2 = "";
        this.info.after_day = "";
        this.value1 = [];
      }
    },
    expired_day2: function(val) {
      if (val) {
        this.info.use_date_type = 3;
        this.expired_day1 = "";
        this.value1 = [];
      }
    },
  },
  components: {},
  //生命周期
  created() {
    console.log(this.info, 22222);
    if (this.info.use_date_type == 1 && this.info.start_time) {
      this.value1 = [this.info.start_time, this.info.end_time];
    } else if (this.info.use_date_type == 2) {
      this.expired_day1 = this.info.expired_day;
    } else if (this.info.use_date_type == 3) {
      this.expired_day2 = this.info.expired_day;
    }
    if (this.operate == "edit") {
      this.operate_type = true;
    }
    this._getGoodsSelect();
  },
  methods: {
    changeDateType(e) {
      console.log(e, "212");
      this.info.date_type = e;
    },
    //商品下拉选择
    _getGoodsSelect() {
      getGoodsSelect().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    onSuccessCover(urlAddr) {
      console.log(urlAddr);
      this.info.cover = urlAddr;
    },
    changeDate(e) {
      this.info.start_time = e[0];
      this.info.end_time = e[1];
      console.log(e, this.info);
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
      this.$emit("getList");
    },
    _postVoucher(data) {
      postVoucher(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("新增成功");
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //确定保存
    handleConfirm(row) {
      console.log(this.info);
      if (this.type == 3 && !this.info.new_type)
        return this.$message.error("请选择优惠券类型");
      else if (!this.info.name) return this.$message.error("请填写优惠券名称");
      else if (!this.info.quantity)
        return this.$message.error("请填写发放总量");
      else if (!this.info.goods_type)
        return this.$message.error("请选择适用商品");
      else if (this.info.goods_type != 1 && this.info.goods_ids.length == 0)
        return this.$message.error("请选择指定商品");
      else if (!this.info.use_type)
        return this.$message.error("请选择适用门槛");
      else if (this.info.use_type == 2 && !this.info.full_amount)
        return this.$message.error("请输入适用门槛金额");
      else if ((this.type == 1 || this.info.new_type == 1) && !this.info.amount)
        return this.$message.error("请输入用券减免金额");
      else if (
        (this.type == 2 || this.info.new_type == 2) &&
        (!this.info.discount || !this.info.max_amount)
      )
        return this.$message.error("请将优惠内容填写完整");
      else if (!this.info.use_date_type)
        return this.$message.error("请选择用券时间");
      else if (this.info.use_date_type == 1 && this.value1.length != 2)
        return this.$message.error("请选择开始和结束时间");
      else if (this.info.use_date_type == 2 && !this.expired_day1)
        return this.$message.error("请输入有效期");
      else if (
        this.info.use_date_type == 3 &&
        (!this.info.after_day || !this.expired_day2)
      )
        return this.$message.error("请将用券时间填写完整");
      else if (!this.info.receive_type)
        return this.$message.error("请选择领取次数限制");
      else if (this.info.receive_type == 2 && !this.info.receive_count)
        return this.$message.error("请输入领取次数");
      else {
        console.log("我输入完成了");
        if (this.info.use_date_type != 1) {
          this.info.expired_day = this.expired_day1 || this.expired_day2;
        }
        this.info.type = this.type;
        if(this.operate == "edit"){
          putVoucher(this.info).then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.$emit("getList");
              this.closeDrawer();
            } else {
              this.$message.error(res.msg);
            }
          });
        }else{
          this._postVoucher(this.info);
        }
      }
    },
  },
};
</script>

<template>
  <div class="gg-container content">
    <div style="margin-bottom:20px;">
      <span style="font-size:25px;border-bottom:3px solid #1684FC;"
        >新增{{ type == 1 ? "满减" : type == 2 ? "折扣" : "新人" }}优惠券</span
      >
    </div>
    <div class="content">
      <div style="margin-bottom:20px;" v-if="type == 3">
        <span style="margin-right:20px;width:350px;"
          ><span style="color:red">*</span>优惠券类型</span
        >
        <el-select
          :disabled="operate_type"
          filterable
          clearable
          v-model="info.new_type"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>优惠券名称</span
        >
        <el-input
          v-model="info.name"
          size="mini"
          class="input"
          :disabled="operate_type"
        ></el-input>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>发放总量</span
        >
        <el-input v-model="info.quantity" size="mini" class="input"></el-input>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>适用商品</span
        >
        <div style="display: flex;">
          <div style="display: flex;flex-direction: column;">
            <el-radio
              v-model="info.goods_type"
              :label="1"
              :disabled="operate_type"
              >全部商品可用</el-radio
            >
            <el-radio
              v-model="info.goods_type"
              :label="2"
              style="margin:10px 0"
              :disabled="operate_type"
              >指定商品可用</el-radio
            >
            <el-radio
              v-model="info.goods_type"
              :label="3"
              :disabled="operate_type"
              >指定商品不可用</el-radio
            >
          </div>
        </div>
      </div>
      <div
        style="display: flex;margin-bottom:20px;"
        v-if="info.goods_type == 2 || info.goods_type == 3"
      >
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span
          >{{ info.goods_type == 2 ? "适用商品" : "不适用商品" }}</span
        >
        <div style="display: flex;">
          <div v-if="info.goods_type == 2 || info.goods_type == 3">
            <el-select
              filterable
              clearable
              v-model="info.goods_ids"
              multiple
              placeholder="请选择"
              size="mini"
              :disabled="operate_type"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.goods_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>使用门槛</span
        >
        <div style="display: flex;flex-direction: column;">
          <el-radio v-model="info.use_type" :label="1" :disabled="operate_type"
            >无门槛</el-radio
          >
          <el-radio v-model="info.use_type" :label="2" :disabled="operate_type"
            >订单满<el-input
              :disabled="operate_type"
              size="mini"
              v-model="info.full_amount"
              class="input-radio"
            ></el-input
            >元可用</el-radio
          >
        </div>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>优惠内容</span
        >
        <div style="display: flex;" v-if="type == 1 || info.new_type == 1">
          减免<el-input
            :disabled="operate_type"
            size="mini"
            v-model="info.amount"
            class="input-radio"
          ></el-input
          >元
        </div>
        <div style="display: flex;" v-else>
          打<el-input
            :disabled="operate_type"
            size="mini"
            v-model="info.discount"
            class="input-radio"
          ></el-input
          >折，最多<el-input
            :disabled="operate_type"
            size="mini"
            v-model="info.max_amount"
            class="input-radio"
          ></el-input
          >元
        </div>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>用券时间</span
        >
        <el-radio-group
          v-model="info.use_date_type"
          style="display: flex;flex-direction: column;"
          @change="changeDateType"
        >
          <el-radio :label="1">
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="changeDate"
              size="mini"
            >
            </el-date-picker
          ></el-radio>
          <el-radio :label="2" style="margin:10px 0"
            >领券后立即生效,有效期<el-input
              size="mini"
              v-model="expired_day1"
              class="input-radio"
            ></el-input
            >天</el-radio
          >
          <el-radio :label="3" style="margin:10px 0"
            >领券<el-input
              size="mini"
              v-model="info.after_day"
              @input="$forceUpdate()"
              class="input-radio"
            ></el-input
            >天后生效,有效期<el-input
              size="mini"
              v-model="expired_day2"
              class="input-radio"
            ></el-input
            >天</el-radio
          >
        </el-radio-group>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red;display: none;">*</span>使用说明</span
        >
        <el-input
          style="width:300px;"
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="info.remark"
        >
        </el-input>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>领取次数</span
        >
        <div style="display: flex;flex-direction: column;">
          <el-radio
            v-model="info.receive_type"
            :label="1"
            style="margin-bottom:10px"
            :disabled="operate_type"
            >不限次数</el-radio
          >
          <el-radio
            v-model="info.receive_type"
            :label="2"
            :disabled="operate_type"
            ><el-input
              :disabled="operate_type"
              size="mini"
              v-model="info.receive_count"
              class="input-radio"
            ></el-input
            >次</el-radio
          >
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="closeDrawer">取 消</el-button>
      <el-button type="primary" size="small" @click="handleConfirm('info')"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.input {
  width: 200px;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
.input-radio {
  width: 60px;
  margin: 0 15px;
}
</style>
