import request from '@/utils/request'

//admin/banner列表
export function getBannerList(params) {
    return request({
        url: '/admin/banner',
        method: 'GET',
        params
    })
}
//banner启用状态

export function postBannerStatus(data) {
    return request({
        url: '/admin/banner/status/' + data.id,
        method: 'post',
        data
    })
}
//编辑
export function putBannerEdit(data) {
    return request({
        url: '/admin/banner/' + data.id,
        method: 'PUT',
        data
    })
}
//新增banner
export function postBanner(data) {
    return request({
        url: '/admin/banner',
        method: 'POST',
        data
    })
}
//删除banner
export function deleteBanner(data) {
    return request({
        url: '/admin/banner/' + data.id,
        method: 'DELETE',
        data
    })
}

//优惠券列表
export function getVoucherList(params) {
    return request({
        url: '/admin/voucher',
        method: 'GET',
        params
    })
}
//优惠券详情
export function getVoucherInfo(id) {
    return request({
        url: `/admin/voucher/${id}}`,
        method: 'GET',
    })
}
//删除优惠券
export function deleteVoucher(data) {
    return request({
        url: '/admin/deleteVoucher',
        method: 'DELETE',
        data
    })
}
//编辑优惠券

export function putVoucher(data) {
    return request({
        url: '/admin/voucher/'+data.id,
        method: 'put',
        data
    })
}
//新增优惠券
export function postVoucher(data) {
    return request({
        url: '/admin/voucher',
        method: 'POST',
        data
    })
}

//促销活动列表
export function getPromotionList(params) {
    return request({
        url: '/admin/promotion',
        method: 'GET',
        params
    })
}
//新增促销活动
export function postPromotion(data) {
    return request({
        url: '/admin/promotion',
        method: 'POST',
        data
    })
}
//编辑促销活动
export function putPromotion(data) {
    return request({
        url: '/admin/promotion/'+data.id,
        method: 'PUT',
        data
    })
}
//删除促销活动
export function deletePromotion(data) {
    return request({
        url: '/admin/deletePromotion',
        method: 'DELETE',
        data
    })
}

//秒杀活动列表
export function getSeckillList(params) {
    return request({
        url: '/admin/seckill',
        method: 'GET',
        params
    })
}
//新增秒杀商品
export function postSeckill(data) {
    return request({
        url: '/admin/seckill',
        method: 'POST',
        data
    })
}
//删除秒杀商品
export function deleteSeckill(data) {
    return request({
        url: '/admin/deleteSeckill',
        method: 'DELETE',
        data
    })
}
//编辑秒杀商品
export function putSeckill(data) {
    return request({
        url: '/admin/seckill/' + data.id,
        method: 'PUT',
        data
    })
}